const LABEL_MONTHS = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export const numberWithZero = (value: number) => {
  if (value < 0) {
    return value;
  }

  if (value < 10) {
    return `0${value}`;
  }

  return value;
};

export const formatDateRange = (date1: Date, date2: Date, withTime = false) => {
  const day1 = date1.getDate();
  const day2 = date2.getDate();

  const month1 = date1.getMonth();
  const month2 = date2.getMonth();

  const year1 = date1.getFullYear();
  const year2 = date2.getFullYear();

  let time = "";
  if (withTime) {
    time = `, ${numberWithZero(date1.getHours())}:${numberWithZero(
      date1.getMinutes()
    )}`;
  }

  if (date1.getTime() === date2.getTime()) {
    return `${day1} ${LABEL_MONTHS[month1]} ${year1}${time}`;
  }

  if (month1 === month2 && year1 === year2) {
    return `${day1} – ${day2} ${LABEL_MONTHS[month1]} ${year1}${time}`;
  }

  if (year1 === year2) {
    return `${day1} ${LABEL_MONTHS[month1]} – ${day2} ${LABEL_MONTHS[month2]} ${year1}${time}`;
  }

  return `${day1} ${LABEL_MONTHS[month1]} ${year1} – ${day2} ${LABEL_MONTHS[month2]} ${year2}${time}`;
};
