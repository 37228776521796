const CalendarIcon = ({ isDark }: { isDark?: boolean }) => {
  // return isDark ? (
  //   <svg
  //     width="21"
  //     height="20"
  //     viewBox="0 0 21 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M6.125 2.5V4.375M14.875 2.5V4.375M3 15.625V6.25C3 5.21447 3.83947 4.375 4.875 4.375H16.125C17.1605 4.375 18 5.21447 18 6.25V15.625M3 15.625C3 16.6605 3.83947 17.5 4.875 17.5H16.125C17.1605 17.5 18 16.6605 18 15.625M3 15.625V9.375C3 8.33947 3.83947 7.5 4.875 7.5H16.125C17.1605 7.5 18 8.33947 18 9.375V15.625M10.5 10.625H10.5063V10.6313H10.5V10.625ZM10.5 12.5H10.5063V12.5063H10.5V12.5ZM10.5 14.375H10.5063V14.3813H10.5V14.375ZM8.625 12.5H8.63125V12.5063H8.625V12.5ZM8.625 14.375H8.63125V14.3813H8.625V14.375ZM6.75 12.5H6.75625V12.5063H6.75V12.5ZM6.75 14.375H6.75625V14.3813H6.75V14.375ZM12.375 10.625H12.3813V10.6313H12.375V10.625ZM12.375 12.5H12.3813V12.5063H12.375V12.5ZM12.375 14.375H12.3813V14.3813H12.375V14.375ZM14.25 10.625H14.2563V10.6313H14.25V10.625ZM14.25 12.5H14.2563V12.5063H14.25V12.5Z"
  //       stroke="#222426"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //     />
  //   </svg>
  // ) : (
  //   <svg
  //     width="20"
  //     height="20"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M5.625 2.5V4.375M14.375 2.5V4.375M2.5 15.625V6.25C2.5 5.21447 3.33947 4.375 4.375 4.375H15.625C16.6605 4.375 17.5 5.21447 17.5 6.25V15.625M2.5 15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625M2.5 15.625V9.375C2.5 8.33947 3.33947 7.5 4.375 7.5H15.625C16.6605 7.5 17.5 8.33947 17.5 9.375V15.625M10 10.625H10.0063V10.6313H10V10.625ZM10 12.5H10.0063V12.5063H10V12.5ZM10 14.375H10.0063V14.3813H10V14.375ZM8.125 12.5H8.13125V12.5063H8.125V12.5ZM8.125 14.375H8.13125V14.3813H8.125V14.375ZM6.25 12.5H6.25625V12.5063H6.25V12.5ZM6.25 14.375H6.25625V14.3813H6.25V14.375ZM11.875 10.625H11.8813V10.6313H11.875V10.625ZM11.875 12.5H11.8813V12.5063H11.875V12.5ZM11.875 14.375H11.8813V14.3813H11.875V14.375ZM13.75 10.625H13.7563V10.6313H13.75V10.625ZM13.75 12.5H13.7563V12.5063H13.75V12.5Z"
  //       stroke="#6C7278"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //     />
  //   </svg>
  // );
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10827_43818)">
        <path
          d="M11.332 2.00002H13.9987C14.1755 2.00002 14.3451 2.07026 14.4701 2.19528C14.5951 2.32031 14.6654 2.48988 14.6654 2.66669V13.3334C14.6654 13.5102 14.5951 13.6797 14.4701 13.8048C14.3451 13.9298 14.1755 14 13.9987 14H1.9987C1.82189 14 1.65232 13.9298 1.52729 13.8048C1.40227 13.6797 1.33203 13.5102 1.33203 13.3334V2.66669C1.33203 2.48988 1.40227 2.32031 1.52729 2.19528C1.65232 2.07026 1.82189 2.00002 1.9987 2.00002H4.66536V0.666687H5.9987V2.00002H9.9987V0.666687H11.332V2.00002ZM13.332 6.00002V3.33335H11.332V4.66669H9.9987V3.33335H5.9987V4.66669H4.66536V3.33335H2.66536V6.00002H13.332ZM13.332 7.33335H2.66536V12.6667H13.332V7.33335ZM3.9987 8.66669H7.33203V11.3334H3.9987V8.66669Z"
          fill="#8A9299"
        />
      </g>
      <defs>
        <clipPath id="clip0_10827_43818">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export { CalendarIcon };
