export enum EventStatuses {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DECLINED = "DECLINED",
  MODERATING = "MODERATING",
}

export type EventFilters = {
  dates: [Date | null, Date | null];
  online: boolean | null;
  cities: string[];
  categories: string[];
  organization: string[];
  themes: string[];
  peoples: [number | null, number | null];
  targets: string[];
  partners: string[];
  favorite: boolean;
};
