import { EventStatuses } from "../event-types";

const getEventStatus = (status: EventStatuses) => {
  switch (status) {
    case EventStatuses.ACTIVE:
      return "Опубликовано";

    case EventStatuses.DRAFT:
      return "Черновик";

    case EventStatuses.DECLINED:
      return "Отклонено";

    case EventStatuses.MODERATING:
      return "На модерации";

    default:
      return "Неизвестно";
  }
};

export default getEventStatus;
